import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Footer } from "../components/Footer";
import { Team } from "../components/Team";
import { Service } from "../components/Serviceitems";

export default () => {
  return (
    <Layout>
      <Service />
      <Team />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
