import styled from "styled-components";

export const EnContainer = styled.div`
  width: 500px;
  height: 200px;
  display: flex;
  .info {
    width: 340px;
    height: 100%;
    padding-left: 12px;
    background-color: #595959;
    color: #fff;
    font-size: 14px;
    .list {
      display: flex;
      margin-top: 16px;
      line-height: 1.5;
      .title {
        width: 85px;
      }
    }
  }
`;

export const EnPhoto = styled.div`
  height: auto;
  width: 160px;
  background-image: ${(props) => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
