import React from "react";
import styled from "styled-components";
import serviceImg1 from "../../images/serviceImg1.png";
import serviceImg2 from "../../images/serviceImg2.png";
import serviceImg3 from "../../images/serviceImg3.png";
import Banner from "../../images/about/bg.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { NavBar } from "../nav-bar";

export const Service = () => {
  const { i18n } = useTranslation();
  return (
    <Container bg={Banner}>
      <NavBar main />
      <ServiceContent>
        {i18n.language === "zh" ? (
          <ServiceP1>高度与深度，我们从不妥协，创新与服务，为您不懈护航</ServiceP1>
        ) : (
          <ServiceP1 style={{ lineHeight: "1.2" }}>
            Clarity of vision. Execution with confidence.
            <br />
            Innovation and excellence with no compromise.
          </ServiceP1>
        )}
        {i18n.language === "zh" ? (
          <ServiceItems>
            <ServiceImg1 src={serviceImg1} />
            <ServiceItemsP1>
              投资与估值 <br />
              大数据SaaS平台
            </ServiceItemsP1>
            <ServiceImg2 src={serviceImg2} />
            <ServiceItemsP2>
              投资与投后 <br /> 数字化BI管理系统
            </ServiceItemsP2>
            <ServiceImg3 src={serviceImg3} />
            <ServiceItemsP3>
              金融地产 <br />
              管理咨询与资产方案
            </ServiceItemsP3>
          </ServiceItems>
        ) : (
          <ServiceItems>
            <ServiceImg1 src={serviceImg1} />
            <ServiceItemsP1>Big Data</ServiceItemsP1>
            <ServiceImg2 src={serviceImg2} />
            <ServiceItemsP2>
              Asset Management
              <br />
              Solutions
            </ServiceItemsP2>
            <ServiceImg3 src={serviceImg3} />
            <ServiceItemsP3>Strategy & Planning</ServiceItemsP3>
          </ServiceItems>
        )}
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          {i18n.language === "zh" ? (
            <ServiceP2>
              晤桥亚洲是中国地产金融资产管理数字化方案的领导者，公司的AMSS大数据、 <br />
              AMSS数字化资管和管理咨询三大产品线服务于主要金融和地产机构。
            </ServiceP2>
          ) : (
            <ServiceP2 style={{ fontSize: "24px", lineHeight: "2.5" }}>
              Bridge5 Asia is the leading provider of asset management solutions in China.
              <br />
              Our three business lines serve top real estate and financial firms.
            </ServiceP2>
          )}
        </div>
      </ServiceContent>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-width: 1440px;
  width: 100%;
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: center;
  background-image: ${(props) => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const ServiceContent = styled.section`
  width: 1107px;
  height: 438px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: 0.31px;
  color: #35383b;
  margin-top: 120px;
  position: relative;
  img {
    width: 180px;
    height: 120px;
  }
`;
const ServiceP1 = styled.p`
  text-align: center;
  font-size: 36px;
  font-weight: 700;
`;
const ServiceItems = styled.div`
  p {
    width: 190px;
    height: 45px;
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: 0.6px;
    text-align: center;
    color: #253042;
    margin: 0;
  }
`;
const ServiceItemsP1 = styled.p`
  position: absolute;
  top: 250px;
  left: 185px;
  font-size: 18px;
  font-weight: 700;
`;
const ServiceItemsP2 = styled.p`
  position: absolute;
  top: 250px;
  left: 470px;
  font-weight: 700;
`;
const ServiceItemsP3 = styled.p`
  position: absolute;
  top: 250px;
  right: 155px;
  font-weight: 700;
`;
const ServiceImg1 = styled.img`
  position: absolute;
  top: 80px;
  left: 190px;
`;
const ServiceImg2 = styled.img`
  position: absolute;
  top: 80px;
  left: 480px;
`;
const ServiceImg3 = styled.img`
  position: absolute;
  top: 80px;
  right: 160px;
`;
const ServiceP2 = styled.p`
  position: absolute;
  bottom: 0;
  font-size: 24px;
`;
