import React, { useState } from "react";
import styled from "styled-components";
import joe from "../../../images/Joe.png";
import { EnContainer, EnPhoto } from "./style";

export const Joe = () => {
  const [joeStyle, setJoeStyle] = useState(false);

  const onMouseEnterJoe = () => {
    setJoeStyle(true);
  };
  const onMouseLeaveJoe = () => {
    setJoeStyle(false);
  };

  return (
    <Container onMouseEnter={onMouseEnterJoe} onMouseLeave={onMouseLeaveJoe}>
      <JoeImg src={joe} />
      {joeStyle ? (
        <JoeInfo>
          <JoeInfoP1>周寅生 Joe Zhou</JoeInfoP1>
          <JoeInfoP2>
            <p>工作经历：晤桥亚洲、腾讯、土豆网</p>
            <p>学历：清华大学计算机科学学士</p>
          </JoeInfoP2>
        </JoeInfo>
      ) : (
        <JoeTag>
          <p>周寅生 Joe Zhou</p>
        </JoeTag>
      )}
    </Container>
  );
};

export const EnJoe = () => {
  return (
    <EnContainer>
      <EnPhoto bg={joe} alt="" />
      <div className="info">
        <div className="list">
          <div className="title">Partner:</div>
          <div className="list-info">Joe Zhou</div>
        </div>
        <div className="list">
          <div className="title">Experience:</div>
          <div className="list-info">
            Bridge5 Asia
            <br />
            Tencent
            <br />
            Tudou Net
          </div>
        </div>
        <div className="list">
          <div className="title">Education:</div>
          <div className="list-info">Tsinghua University CS</div>
        </div>
      </div>
    </EnContainer>
  );
};

const Container = styled.div`
  width: 249px;
  height: 249px;
  position: relative;
`;

const JoeImg = styled.img`
  width: 249px;
  height: 249px;
`;
const JoeInfo = styled.div`
  width: 249px;
  height: 249px;
  background-color: #2f3852;
  opacity: 0.75;
  position: absolute;
  top: 0;
  left: 0;
`;
const JoeInfoP1 = styled.p`
  width: 249px;
  height: 20px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 40px;
`;
const JoeInfoP2 = styled.div`
  height: 187px;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.35px;
  color: #ffffff;
  padding: 0 17px 0 17px;
  p {
    margin: 0;
  }
`;
const JoeTag = styled.div`
  width: 249px;
  height: 50px;
  opacity: 0.75;
  background-color: #2f3852;
  position: absolute;
  bottom: 0;
  p {
    width: 240px;
    height: 21px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    margin-top: 20px;
    color: #ffffff;
  }
`;
